/* .App {
  display: ;
} */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
h3, h2{
  font-size:22px; 
  font-weight: 600;
}
body {
  font-family: 'Fira Sans', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0efef;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 500vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type="text"], select {
  width: 100%;
}
.page_sec {
  height: calc(100vh - 78px);
  overflow: auto;
  max-width: 100%;
  width: 100%;
  margin: 0!important;
  padding: 0 10px;
  background-color: #f2f5f9;
}
.row > *{
  margin-right: 0!important;
}
.skeleton_loading {
  animation: shine 1.5s linear infinite;
  background: #eee;
  background: linear-gradient(110deg,#ececec 8%,#f5f5f5 18%,#ececec 33%);
  background-size: 200% 100%;
  border-radius: 5px;
}

@keyframes shine {
  to {
      background-position-x: -200%
  }
}
.skeleton-container {
  position: fixed;
  width: calc(100% - var(--sidebarOpn-width) - 0.5rem - 20px);
  right: 15px;
  top: 83px;
  height: calc(100% - 6rem);
  background: #fff;
  z-index: 999;
  padding: 1.5rem;
  display: none;
}
.loading .skeleton-container {
  display: block;
}
.spinner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}