:root{
  --sidebarOpn-width: 220px;
  --sidebarCls-width: 58px
}

.sidebar{
  transition: all .2s ease-in-out;
}

.link_text {
  display: none;
}

.sidebar .link_text,
.icon.show {
  display: block;
}
.content
 {
  overflow: hidden;
    display: -webkit-inline-box;
    width: 100%;
    background-color: #f0efef;
}

.sidebar {
  background: #e1e5eb;
  height: 100vh;
  text-underline-position: under;
  overflow: auto;
}

/* width */
.sidebar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.sidebar.closed {
  width: 50px;
}
.icon.show {
  width: auto;
}
.top_section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 12px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 50px;
  border-radius: 0;
  object-fit: cover;
}
.hexalogo-image {
  height: 100px;
}

.bars {
  display: flex;
  align-items: center;
}

.menu_with_submenu {
  position: relative;;
}


.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
}


.menu_link {
  display: flex;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  color: #333;
}

.menu_text {
  margin-left: 15px;
  margin-top: -13%;
  font-size: 21px;
}

.link {
  display: flex;
  align-items: center;
  color: #000a19;
  font-size: 16px;
  padding: 15px 25px;
  /* height:45px; */
  margin-top: 10px;
  text-decoration: none;
  opacity: .8;
  transition: all .3s ease;
}

.link:hover {
  color: #0d6efd;
  background-color: transparent;
}
.link .active:hover {
  color: #0d6efd;
  background-color: transparent;
}

.link .active {
  background: #ccc;
  color: #030303;

}

.link_text {
  display: none;
  margin-left: 18px;
  align-items: left;
  text-decoration: none;

}

.submenu {
  position: relative;
  border-style: none;
  /* display: none; */
}

.active {
  color: #0d6efd;
  background-color: transparent;
}

/* Add this CSS to reduce the margin between active box elements when the sidebar is closed */
.sidebar .menu_with_submenu .submenu .link {
  margin: 0;
  padding: 0;
  padding-left: 55px;
  line-height: 3;
  opacity: .8;
  transition: all .2s;
}
.sidebar .menu_with_submenu .submenu .link:hover{
  opacity: 1;
}

.sidebar .link {
  margin: 0;
  cursor: pointer;
}

/* Add this CSS to reduce the margin between active box elements when the sidebar is open */
.sidebar .menu_with_submenu .submenu .active {
  margin: 5px 0;
}

.sidebar .active {
  /* margin: 5px 0; */
}














